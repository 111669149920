import createGenericClient from '../../genericClient';

import { getNotFoundLayout } from '../../layouts/GenericLayout';
const layout = getNotFoundLayout();
import components from '../../components/errors/NotFound';

const bundle = {};
bundle[layout.bundleName] = layout;
bundle[components.bundleName] = components;
createGenericClient(bundle);

