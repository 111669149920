import 'core-js/shim';
import 'regenerator-runtime/runtime';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

import _ from 'src/domain/libs/util';
import FalcorDataSource from 'falcor-http-datasource';

import PathEvaluator from 'src/sketch-platform/utils/PathEvaluator';
import genericApp from 'src/apps/generic/genericApp';
import * as COOKIE from 'src/constants/cookie';
import { ReactClientRenderer } from 'src/sketch-platform/renderers';
import history from 'src/apps/history';

// @ts-ignore TS7006
export default function createGenericClient(bundle) {
  window.app = window.app || {};
  window.app.reactContext = window.app.reactContext || {};

  let endpoint = '/pathEvaluator';
  if (_.get(window, 'app.reactContext.models.userInfo.data.status') === 'NON_REGISTERED_MEMBER') {
    const deviceCode = _.get(window, 'app.reactContext.models.browserInfo.data.deviceCode');
    endpoint = `/anon/${deviceCode}/path`;
  }

  const pathEvaluator = new PathEvaluator({
    collectRatio: 0.75,
    source: new FalcorDataSource(endpoint, {
      headers: {
        'If-Modified-Since': 'Thu, 01 Jan 1970 00:00:00 GMT',
      },
      timeout: 30 * 1000, // デフォルト15秒
    }),
    errorSelector(error) {
      console.error('errorSelector', error);
      error.$expires = -1000 * 60 * 2;
    },
  });
  pathEvaluator.setCache(window.app.falcorCache);

  const model = _.assign({ pathEvaluator }, _.cloneDeep(window.app.reactContext));
  if (model.layout && bundle[model.layout]) {
    model.layoutComponent = bundle[model.layout];
  }
  if (model.template && bundle[model.template]) {
    model.templateComponent = bundle[model.template];
  }

  const container = document.getElementById('appMountPoint');

  // @ts-ignore TS2554
  const appContext = genericApp.createContext({ model, renderSource: 'client', history });

  window.onpageshow = function(event) {
    // console.log(event);
    // @ts-ignore TS2551
    const cookieUid = appContext.cookieDough.get(COOKIE.PROFILE_UID);
    const activeUid = _.get(appContext.getModelData('memberContext'), 'profileData.active.' + COOKIE.PROFILE_UID);
    // console.log(cookieUid, activeUid);
    const disableBFCache = _.get(appContext.getModelData('browserInfo'), 'features.disableBFCache');
    if (event.persisted || (window.performance && window.performance.navigation.type == 2)) {
      if (disableBFCache || cookieUid != activeUid) {
        window.location.reload();
      }
    }
  };

  // @ts-ignore TS7006
  new ReactClientRenderer(container, appContext).render((err, a) => {
    if (err) {
      console.error(err);
    } else {
      console.log(a);
    }
  });
}
