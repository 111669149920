import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';
import { Helmet } from 'react-helmet';
import window from 'global/window';

import layoutContext from '../../common/hocs/layoutContext';
import htmlContext from '../../common/hocs/htmlContext';

import Footer from '../../common/components/Footer';
import Pagetop from '../../common/components/Pagetop';

import ModalContext from '../../common/context/ModalContext';
import ModalContent from '../../common/components/ModalContent';

import URLGenerator from '../../../sketch-platform/ui/routing/URLGenerator';
import * as browserEvents from '../../../sketch-platform/utils/browserEvents';
import getColumnsInRow from '../../common/utils/getColumnsInRow';
import LayoutContext from '../../common/context/LayoutContext';
import DeepLinkLauncher from 'src/apps/bluerose/components/deepLink/DeepLinkLauncher';
import GlobalMenu from 'src/apps/common/components/GlobalMenu';

type GenericLayoutProps = any;
type GenericLayoutState = {
  isSidemenuOpened?: boolean;
  [key: string]: any;
};

export function getGenericLayout(settings = {}) {
  class GenericLayout extends Component<GenericLayoutProps, GenericLayoutState> {
    static bundleName = 'layouts/GenericLayout';

    static get contextTypes() {
      return {
        models: PropTypes.object,
        getModelData: PropTypes.func,
        routeHandler: PropTypes.object,
        history: PropTypes.object,
      };
    }

    static get childContextTypes() {
      return {
        node: PropTypes.object,
        columnsInRow: PropTypes.number,
        orientation: PropTypes.string,
      };
    }

    static getPrefetchPaths = function(models, options, props) {
      return GlobalMenu.getPaths(models, options, props);
    };

    constructor(props, context) {
      super(props, context);
      this.showModal = this.showModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.checkMediaQuery = _.throttle(this.checkMediaQuery.bind(this), 300, { leading: true, trailing: true });
      this.afterDeepLinkLaunch = this.afterDeepLinkLaunch.bind(this);
      this.onChangeSideMenuOpened = this.onChangeSideMenuOpened.bind(this);

      this.state = {
        showCookieDisclosure: false,
        showUma: false,
        umaModal: {},
        onetimeTokenData: context.getModelData('onetimeTokenData'),
        isSidemenuOpened: GlobalMenu.getMenuType(context.models, {}, props) === 'sidemenu',
      };
    }

    getChildContext() {
      return {
        // @ts-ignore TS2339
        node: this.props.model.node,
        // @ts-ignore TS2339
        columnsInRow: this.state.columnsInRow,
        // @ts-ignore TS2339
        orientation: this.state.orientation,
      };
    }

    componentDidMount() {
      // @ts-ignore TS2339
      this._isMounted = true;
      // wtokenが付与されている場合、画面表示した時に消す
      // @ts-ignore TS2339
      const routeHandler = this.props.routeHandler || this.context.routeHandler;
      if (routeHandler.query.wtoken) {
        const query = _.omit(routeHandler.query, 'wtoken');
        const path = URLGenerator.createRelative({ path: routeHandler.path, query });
        this.context.history.replace(path, { norender: true });
      }
      this.checkMediaQuery();
      const browserInfo = this.context.getModelData('browserInfo');
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        browserEvents.addEventListener('orientationchange', this.checkMediaQuery);
      } else {
        browserEvents.addEventListener('resize', this.checkMediaQuery);
      }
    }

    componentWillUnmount() {
      // @ts-ignore TS2339
      this._isMounted = false;
      const browserInfo = this.context.getModelData('browserInfo');
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        browserEvents.removeEventListener('orientationchange', this.checkMediaQuery);
      } else {
        browserEvents.removeEventListener('resize', this.checkMediaQuery);
      }
    }

    checkMediaQuery() {
      if (typeof window === 'undefined') return;

      let orientation;
      const browserInfo = this.context.getModelData('browserInfo');
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        /**正面設定が縦で縦向き・正面設定が横で縦向き */
        const verticalTypes = ['portrait-primary', 'portrait-primary'];
        /**正面設定が横で横向き・正面設定が縦で横向き */
        const horizontalTypes = ['landscape-primary', 'landscape-secondary'];
        // @ts-ignore TS2551
        const type = screen.msOrientation || screen.mozOrientation || (screen.orientation || {}).type;
        if (type) {
          if (_.includes(verticalTypes, type)) orientation = 'vertical';
          else if (_.includes(horizontalTypes, type)) orientation = 'horizontal';
        }
        // safariなど上に対応していないものもあるため
        if (!orientation) {
          // 縦
          if (window.orientation % 180 === 0) orientation = 'vertical';
          // 横
          else orientation = 'horizontal';
        }
      }

      let columnsInRow = getColumnsInRow();
      if (orientation == 'horizontal' && orientation == 'horizontal') {
        columnsInRow = 3;
      }
      const spMode = columnsInRow === 2;

      // @ts-ignore TS2339
      if (spMode !== this.state.spMode) {
        this.setState({ spMode });
      }
      // @ts-ignore TS2339
      if (columnsInRow !== this.state.columnsInRow) {
        this.setState({ columnsInRow, orientation });
      }
    }

    showModal(component, props) {
      // @ts-ignore TS2339
      const modalComponents = this.state.modalComponents || [];
      modalComponents.push(
        <ModalContent key={`modal-content-${modalComponents.length}`} {...props} onClose={e => this.closeModal()}>
          {component}
        </ModalContent>,
      );
      this.setState({
        modalComponents: modalComponents,
      });
    }

    closeModal() {
      // @ts-ignore TS2339
      const modalComponents = this.state.modalComponents || [];
      if (modalComponents.length > 1) {
        this.setState({
          modalComponents: modalComponents.slice(0, modalComponents.length - 1),
        });
      } else {
        this.setState({
          modalComponents: [],
        });
      }
    }

    onChangeSideMenuOpened(opened: boolean) {
      this.setState({ isSidemenuOpened: opened });
    }

    afterDeepLinkLaunch() {
      this.context.models.onetimeTokenData = null;
      this.setState({ onetimeTokenData: null });
    }

    render() {
      const inapp = this.context.getModelData('inapp', 'inapp');
      const browserInfo = this.context.getModelData('browserInfo');
      // @ts-ignore TS2339
      const globalMenuType = GlobalMenu.getMenuType(this.context.models, {}, settings.headerProps);
      const mainClassName = classnames(
        'main',
        settings['mainClassName'],
        // @ts-ignore TS2339
        this.props.mainClassName,
        _.get(this.context, 'models.layoutProps.mainClassName', {}),
        { 'with-sidemenu': globalMenuType === 'sidemenu', 'sidemenu-is-closed': !this.state.isSidemenuOpened },
      );

      let globalMenuProps = {
        // @ts-ignore TS2339
        model: this.props.model,
        handleClickCallback: cb => {
          // @ts-ignore TS2339
          this._handleClickCallback = cb;
        },
      };
      // @ts-ignore TS2339
      if (typeof settings.headerProps === 'object') {
        // @ts-ignore TS2339
        globalMenuProps = _.assign({}, settings.headerProps, globalMenuProps);
      }
      let footerProps = {};
      // @ts-ignore TS2339
      if (typeof settings.footerProps === 'object') {
        // @ts-ignore TS2339
        footerProps = _.assign({}, settings.footerProps, footerProps);
      }
      // ヘッダーの中身を表示する設定の場合はfixedにする
      const hidePropNames = ['hideNavigation', 'hideAccountLinks', 'hideProfileLinks'];
      if (!_.every(hidePropNames, name => !!globalMenuProps[name])) {
        // @ts-ignore TS2339
        globalMenuProps.fixed = true;
      }
      const modalContextValue = {
        showModal: this.showModal,
        closeModal: this.closeModal,
      };

      const layoutContextValue = {
        // @ts-ignore TS2339
        spMode: this.state.spMode,
      };

      let title;
      React.Children.map(this.props.children, child => {
        // @ts-ignore TS2339
        if (child.type.headTitle) {
          // @ts-ignore TS2339
          title = child.type.headTitle;
        }
      });

      let helmetProps = {};
      // @ts-ignore TS2339
      if (typeof settings.helmetProps === 'object') {
        // @ts-ignore TS2339
        helmetProps = _.assign({}, settings.helmetProps, helmetProps);
      }
      if (_.isEmpty(helmetProps)) {
        // @ts-ignore TS2339
        helmetProps.title = title;
      }

      return (
        <React.Fragment>
          <Helmet {...helmetProps} />
          <div
            lang="ja-JP"
            className={classnames('generic-layout', 'lang_ja', { inapp })}
            dir="ltr"
            onClick={e => {
              // @ts-ignore TS2339
              if (this._handleClickCallback) this._handleClickCallback(e);
            }}
          >
            <ModalContext.Provider value={modalContextValue}>
              <LayoutContext.Provider value={layoutContextValue}>
                <GlobalMenu {...globalMenuProps} onChangeSideMenuOpened={this.onChangeSideMenuOpened} />
                <div className={mainClassName}>{this.props.children}</div>
                {!inapp ? (
                  <Footer
                    {...footerProps}
                    className={classnames({
                      'with-sidemenu': globalMenuType === 'sidemenu',
                      'sidemenu-is-closed': !this.state.isSidemenuOpened,
                    })}
                  />
                ) : null}
                {browserInfo.isIOS || browserInfo.isAndroid ? <Pagetop /> : null}
                {/*
                   // @ts-ignore TS2339 */}
                {this.state.modalComponents}
                {/*
                   // @ts-ignore TS2339 */}
                {!inapp && !this.context.isIframe && this.state.onetimeTokenData ? (
                  <DeepLinkLauncher
                    // @ts-ignore TS2339
                    token={this.state.onetimeTokenData}
                    afterDeepLinkLaunch={this.afterDeepLinkLaunch}
                  />
                ) : null}
              </LayoutContext.Provider>
            </ModalContext.Provider>
          </div>
        </React.Fragment>
      );
    }
  }

  return layoutContext(htmlContext(GenericLayout));
}

const AccountLayoutParams = {
  helmetProps: {
    title: 'WOWOWオンライン',
    titleTemplate: '%s',
    link: [{ rel: 'icon', href: '/favicon_wowow.ico' }],
  },
  headerProps: {
    transparent: true,
    hideNavigation: true,
    hideAccountLinks: true,
    hideProfileLinks: true,
    isAccountLayout: true,
  },
  footerProps: {
    isAccountLayout: true,
  },
};

const SignupLayoutParams = {
  helmetProps: {
    title: 'WOWOWオンライン',
    titleTemplate: '%s',
    link: [{ rel: 'icon', href: '/favicon_wowow.ico' }],
  },
  headerProps: {
    transparent: true,
    hideNavigation: true,
    hideAccountLinks: true,
    hideProfileLinks: true,
    isAccountLayout: true,
    isSignupLayout: true,
  },
  footerProps: {
    isAccountLayout: true,
    isSignupLayout: true,
  },
};

export function getAccountLayout() {
  return getGenericLayout(_.assign({}, AccountLayoutParams));
}

export function getSignupLayout() {
  return getGenericLayout(_.assign({}, SignupLayoutParams));
}

export function getActivationLayout() {
  const params = _.assign({}, AccountLayoutParams);
  // @ts-ignore TS2339
  params.headerProps.hideLogoLink = true;
  // @ts-ignore TS2339
  params.footerProps.hideLinks = true;
  return getGenericLayout(params);
}

export function getProfileLayout(hideNavigation) {
  return getGenericLayout(
    _.assign(
      {},
      {
        mainClassName: 'profiles-body',
        headerProps: {
          transparent: hideNavigation,
          hideNavigation: hideNavigation,
          hideAccountLinks: false,
          hideProfileLinks: hideNavigation,
        },
      },
    ),
  );
}

export function getTermsLayout() {
  return getGenericLayout(
    _.assign(
      {},
      {
        headerProps: {
          transparent: true,
          hideNavigation: true,
          hideAccountLinks: false,
          hideProfileLinks: true,
        },
      },
    ),
  );
}

export function getContractLayout() {
  return getGenericLayout(
    _.assign(
      {},
      {
        headerProps: {
          hideSearchHeader: true,
        },
      },
    ),
  );
}

export function getAuthLayout() {
  return getGenericLayout(
    _.assign(
      {},
      {
        headerProps: {
          transparent: true,
          hideNavigation: true,
          hideAccountLinks: true,
          hideProfileLinks: true,
          menuType: 'header',
        },
        footerProps: {},
      },
    ),
  );
}

export function getNotFoundLayout() {
  return getGenericLayout(
    _.assign(
      {},
      {
        mainClassName: 'error',
        headerProps: {},
      },
    ),
  );
}

export const AccountLayout = getAccountLayout();
export const SignupLayout = getSignupLayout();
export const ActivationLayout = getActivationLayout();
export const ProfileLayout = getProfileLayout(false);
export const ContractLayout = getContractLayout();
export const AuthLayout = getAuthLayout();
export default getGenericLayout();
