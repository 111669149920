import App from '../../sketch-platform/app/App';
import { createRoute } from '../../sketch-platform/ui/routing';
import _ from 'src/domain/libs/util';

import GenericRouteHandler from './GenericRouteHandler';
import routes from '../common/routes';
import GtmApp from '../../common/GtmApp';
import AuthApp from '../../common/AuthApp';
import WatchPartyApp from '../../common/WatchPartyApp';
import PlayerApp from '../../common/PlayerApp';
import FullScreenApp from '../../common/FullScreenApp';

export default new App(
  {
    routeHandlers: [
      {
        route: createRoute({ path: '(.*)' }),
        components: [GenericRouteHandler],
      },
    ],
    routes,
  },
  {
    // @ts-ignore TS7006
    handleCreateContext: function(context) {
      const fsApp = new FullScreenApp();
      const playerApp = new PlayerApp({
        config: _.get(context, 'model.models.config.data', {}),
        browserInfo: _.get(context, 'model.models.browserInfo.data', {}),
        googlecast: _.get(context, 'model.models.googlecast.data', {}),
        fsApp,
      });
      const gtmApp = new GtmApp({
        models: _.get(context, 'model.models', {}),
        config: _.get(context, 'model.models.config.data', {}),
        browserInfo: _.get(context, 'model.models.browserInfo.data', {}),
      });

      const authApp = new AuthApp(_.get(context, 'model.models', {}), {}, _.get(context, ['model', 'node', 'req']));

      const watchPartyApp = new WatchPartyApp(
        authApp,
        _.get(context, 'model.models', {}),
        {},
        _.get(context, ['model', 'node', 'req']),
      );

      return Object.assign({}, context, {
        gtmApp,
        watchPartyApp,
        playerApp,
        fsApp,
      });
    },
  },
);
